import { ApolloProvider } from '@apollo/client';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { appWithTranslation } from 'next-i18next';
import { SnackbarProvider } from 'notistack';

import lightTheme from '../styles/theme/lightTheme';
import client from '../utils/apollo-client';
import createEmotionCache from '../utils/createEmotionCache';

import '../styles/globals.css';

const clientSideEmotionCache = createEmotionCache();

function App(props: { Component: any; emotionCache?: EmotionCache; pageProps: any }) {
    const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

    return (
        <CacheProvider value={emotionCache}>
            <ThemeProvider theme={lightTheme}>
                <CssBaseline />
                <ApolloProvider client={client}>
                    <SnackbarProvider
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    >
                        <Component {...pageProps} />
                    </SnackbarProvider>
                </ApolloProvider>
            </ThemeProvider>
        </CacheProvider>
    );
}

export default appWithTranslation(App);
