import { ApolloClient, ApolloLink, createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { clientValidatedEnv } from '../config/env.client';

import { apolloCache } from './apollo-cache';

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
            console.log(
                // eslint-disable-next-line max-len
                `[GraphQL error]: Message: ${message}, Location: ${locations?.toString()}, Path: ${path?.toString()}`,
            );

            if (message === 'Not authenticated') {
                window.location.href = '/auth/login';
            }
        });
    if (networkError) console.log(`[Network error]: ${networkError?.toString()}`);
});

// const cookies = new Cookies();

const httpLink = createHttpLink({
    uri: clientValidatedEnv.NEXT_PUBLIC_BACKEND_DOMAIN + '/graphql',
    credentials: 'include',
});

const client = new ApolloClient({
    link: ApolloLink.from([errorLink, httpLink]),
    cache: apolloCache,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'network-only',
        },
    },
});

export default client;
