import joi from 'joi';

// this file should only be imported by client-side code



const objectSchema = {
    NEXT_PUBLIC_BACKEND_DOMAIN: joi.string().required(),
    NEXT_PUBLIC_DEBUG_ENV: joi.string().required(),
    NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_KEY: joi.string().required(),
};

// based on Joi typings
interface ClientEnvVariables {
    NEXT_PUBLIC_BACKEND_DOMAIN: string;
    NEXT_PUBLIC_DEBUG_ENV: string;
    NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_KEY: string;
}

// Next.js requires us to spell out every process.env variable for them to work (client-side only)
const variables = {
    NEXT_PUBLIC_BACKEND_DOMAIN: process.env.NEXT_PUBLIC_BACKEND_DOMAIN,
    NEXT_PUBLIC_DEBUG_ENV: process.env.NEXT_PUBLIC_DEBUG_ENV,
    NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_KEY: process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_KEY,
};

const validationSchema = joi.object<ClientEnvVariables>(objectSchema).unknown(true);

// Because Next.js requires us to spell out the properties
const validation = validationSchema.validate(variables);

if (validation.error) {
    // eslint-disable-next-line no-console
    console.error(validation.error.details);

    throw new Error(validation.error.message);
}

export const clientValidatedEnv = validation?.value;
