import { createTheme } from '@mui/material/styles';
import { svSE } from '@mui/x-date-pickers/locales';

const lightTheme = createTheme({
    typography: {
        // allVariants: {
        //     color: 'black',
        // },
        fontFamily: 'Graphik, Roboto',
        h1: {
            fontFamily: 'Chonburi',
            fontWeight: 'normal',
            fontSize: '3.75rem',
            color: '#A7404C',
        },
        h2: {
            fontFamily: 'Chonburi',
            fontWeight: 'bold',
            fontSize: '36px',
            color: '#A7404C',
        },
        h3: {
            fontFamily: 'Chonburi',
            fontWeight: 'bold',
            color: '#A7404C',
            fontSize: '32px',
        },
        h4: {
            fontFamily: 'Chonburi',
            fontWeight: 'bold',
            color: '#A7404C',
            fontSize: '28px',
        },
        h5: {
            fontFamily: 'Graphik',
            fontWeight: 'bold',
            color: '#000',
            fontSize: '24px',
        },
        h6: {
            fontFamily: 'Graphik',
            fontWeight: 'bold',
            color: '#A7404C',
            fontSize: '14px',
            letterSpacing: '0.01em',
        },
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: { variant: 'text' },
                    style: {
                        backgroundColor: 'transparent',
                        borderRadius: 0,
                    },
                },
                {
                    props: { variant: 'contained' },
                    style: {
                        borderRadius: 0,
                        backgroundColor: '#A7404C',
                    },
                },
            ],
        },
    },
    palette: {
        action: {
            hover: '#FFFAFB',
        },
        mode: 'light',
        primary: {
            main: '#A7404C',
            light: '#f0dad8',
        },
        secondary: {
            main: '#A74',
        },
        background: {
            default: '#fff',
        },
    },
}, svSE);

export default lightTheme;
